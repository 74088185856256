<template>
  <v-container>
    <v-row>
      <v-col cols="12" :loading="loading">
        <v-row align="center" justify="center" style="height: 80vh">
          <v-card
            class="ma-3 pa-6 text-center"
            min-width="40%"
          >
            <v-textarea
              outlined
              v-model="form_data.gn_message"
              :error-messages="form_error.gn_message"
              label="Message"
              value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
            ></v-textarea>
            <v-btn color="success" @click="sendNotification">Send</v-btn>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
 
import { mapMutations } from "vuex";
export default {
  name: "AddVendor",
  data() {
    return {
      form_data : {},
      form_error: {},
      dialog: false,
      loading: false,
      data: {},
    };
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    sendNotification() {
      let _self = this;
      _self.loading = "secondary";
      this.$axios.post(`admin/notification/send`, _self.form_data)
        .then((response) => {
          _self.form_error = {};
          let response_data = response.data;
          if (response_data.status) {
            _self.form_data = {}
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
  },
  mounted: function () {
    this.getAttemdeeList();
    this.getUserList();
  },
};
</script>